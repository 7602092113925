export default {
     'login':{
         'title':'ورود به حساب کاربری',
         'edit':' ویرایش اطلاعات کاربری',
         'login':'ورود',
         'exit':'خروج از حساب کاربری ',
         'register':'ثبت نام',
         'password':'رمزعبور',
         'telephone_number':'شماره موبایل',
         'insert_mobilenumber':'سلام! لطفا شماره موبایل خود را وارد کنید',
         'telephone':'شماره تماس',
         'national_code':'کد ملی',
         'driver':'راننده',
         'car_owner':'صاحب ناوگان',
         'Plaque':'پلاک',
         'fullName':'نام و نام خانوادگی',
         'truck_smart_code':'شماره هوشمند ناوگان',
         'smart_code':'شماره هوشمند راننده',
         'confirm_code':'کد تایید',
         'Dont_have_an_account_yet':'حساب کاربری ندارید؟',
         'hca':'سامانه مدیریت حسابداری ناوگان ',
         'have_an_account':'حساب کاربری دارید؟',
         'sms':'ارسال رمز یک بار مصرف',
         'forget_pass':' رمز عبور خود را فراموش کرده اید، اینجا کلیک کنید',
         'contact_us':'تماس با ما'
     },
     'menu':{
        'home':'خانه',
        'report':'گزارشات',
        'costs':'هزینه',
        'salary':'حقوق',
        'truck':'ناوگان',
        'barname':'بارنامه',
        'install':'نصب برنامه'
     },
    'barname':{
        'number':'شماره بارنامه',
        'origin':'مبدا',
        'destination':'مقصد',
        'description':'توضیحات',
        'total_fare_price':'مبلغ کل کرایه',
        'commission_price':'مبلغ  کمسیون (تومان)',
        'date':'تاریخ بارنامه',
        'add_bill':'ایجاد بارنامه جدید',
        'edit_bill':'ویرایش بارنامه',
        'list_billLading':'لیست کرایه بارنامه ها',
        'path':'مسیر',
        'service_count':'تعداد سرویس',
        'detail':'جزییات بارنامه ',
    },
    'table':{
         'show':'نمایش',
         'cancel':'لغو کردن',
         'remove':'حذف کردن',
         'edit':'ویرایش',
         'delete':'حذف',
         'action':'اقدامات',
         'add':'ایجاد',
         'date':'تاریخ',
         'order':'ردیف',
         'details':'جزییات',
         'total_fare_price':' کل کرایه',
         'commission_price':' کمسیون',
    },
    'placeholders':{
        'service_count':'تعداد سرویس را وارد کنید',
        'letter':'الف',
        'cost':'هزینه را به تومان وارد کنید',
        'CostType':'نام هزینه پرداختی را وارد کنید',
        'date':'تاریخ را وارد کنید',
        'password':'رمزعبور را وارد کنید',
        'telephone_number':'شماره موبایل را وارد کنید',
        'driver_telephone_number':'شماره تلفن همراه راننده را وارد کنید',
        'national_code':'کد ملی را وارد کنید',
        'Plaque':'پلاک را وارد کنید',
        'fullName':'نام و نام خانوادگی را وارد کنید',
        'truck_smart_code':'شماره هوشمند ناوگان را وارد کنید',
        'smart_code':'شماره هوشمند راننده را وارد کنید',
        'number':'شماره بارنامه را وارد کنید',
        'origin':'مبدا را وارد کنید',
        'destination':'مقصد را وارد کنید',
        'total_fare_price':'مبلغ کل کرایه را به تومان وارد کنید',
        'commission_price':'مبلغ کمسیون را به تومان وارد کنید',
        'group_cost':'عنوان دسته بندی را وارد کنید',
        'pelak':'پلاک را وارد کنید',
        'price':'مبلغ را به تومان وارد کنید',
        'description':'توضیح را وارد کنید',
        'truck':'ناوگان را انتخاب کنید',
        'driver':'راننده را انتخاب کنید',
        'type':'نوع را انتخاب کنید',
        'salary_type':'نوع حقوق را انتخاب کنید',
        'salary_value':' حقوق را به تومان وارد کنید',
    },
    'buttons': {
        'update':'ویرایش',
        'add':'افزودن',
        'add_driver':'افزودن راننده'
    },
    'labels': {
        'mange':' ویرایش اطلاعات کاربری',
        'telephone_number':'تلفن همراه',
        'national_code':'کد ملی',
        'smart_code':'کد هوشمند',
        'fullName':'نام و نام خانوادگی',
        'trucks':'لیست ناوگان ها',
        'pelak':'پلاک',
        'truck_smart_code':'کد هوشمند ناوگان',
        'date':'تاریخ بارنامه را وارد کنید',
        'add_truck':'افزودن ناوگان',
        'search':'جست و جو کنید. . .',
        'value_salary':'حقوق',
        'type_salary':'نوع حقوق',
        'drivers':'راننده های ناوگان',
    },
    'driversFee':{
        'group_cost':'دسته بندی هزینه ها',
        'title':'عنوان دسته بندی',
        'type':'دسته بندی',
    },
    'CostType':{
        'costs':'هزینه ها',
        'cost':'هزینه (تومان)',
        'type':' نوع هزینه ',
        'types':' نوع هزینه ها',
        'title':'عنوان هزینه',
        'title_cost':'عنوان',
        'salary':'حقوق پرداخت شده',
        'salary_type':'نوع حقوق',
        'price':'مبلغ (تومان)',
        'description':'توضیحات',
        'truck':'ناوگان',
        'driver':'راننده',
        'drivers':'رانندگان',
        'date':'تاریخ',
        'list_salary':'لیست حقوق های پرداختی',
    },
    'home':{
      'switch_truck':'تغییر ناوگان پیش فرض' ,
        'switch':'تغییر ناوگان',
        'add_truck':'افزودن ناوگان',
        'no_truck':' کاربر گرامی ناوگانی با مشخصات شما ثبت نشده است ، در صورتی که صاحب ناوگان هستید گزینه ایجاد ناوگان را کلیک کنید در غیر این صورت اگر راننده هستید اطلاعات خود را به مالک ناوگان برای اضافه کردن راننده در بخش ناوگان خود ، اعلام نمایید.   ',
    },
    'report':{
         'from_date':'از تاریخ',
        'to_date':'تا تاریخ',
         'reports':'گزارشات',
        'total_fare':'جمع کل کرایه بارنامه ها',
        'total_commission':'جمع کل کمسیون ها',
        'total_fare_less_commission':'صافی بارنامه بدون کمسیون',
        'total_fare_less_commission_less_service_costs':'صافی کارکرد با هزینه های سرویس',
        'total_fare_less_commission_less_all_cost':'صافی کارکرد با کل هزینه ها',
        'salaries':'حقوق راننده',
        'driver_sum_taken':'جمع برداشتی راننده',
    }
}